import React from "react";
import {Link} from "react-router-dom";
import "./index.css"
import { FaCheckCircle, FaTimesCircle, FaSpinner, FaQuestionCircle  } from "react-icons/fa";
import openSea from "../../assets/opensea.png";
import { connectWallet } from "../../web3/interact";
import FadeIn from "react-fade-in";

const Home = ({nftCollection, toggle,
  onUpdateWallet,
  walletAddress,
  setStatusState,
  loading, setIsLoading,
    setToggle}) => {


      const connectWalletPressed = async () => {
        setIsLoading(true)
        const walletResponse = await connectWallet();
        setStatusState(walletResponse.status);
        onUpdateWallet(walletResponse.address);
        setIsLoading(false)
        window.location.reload()
      };
    
    

  return (
<section className="s-main-page">
<div class="p-4 s-main-card">
   
      <div class="content rounded-3 p-3 p-10">
        <h1 class="s-main-card-title">Welcome to Metavaders Dapp-Beta</h1>
        <p class="mb-0">Find the different pages below and short description</p>
      </div>

</div>

<div className="s-main-card-container">
<Link class="p-4 s-main-card " to="/activate-deactivate">
  
      <div class="content rounded-3 p-3 p-10">
      <div className="flex align-items">
      <FaCheckCircle />
        <h1 class="s-main-card-title ml-4">Activate</h1>
  
        </div>
        <p class="mb-0 s-main__subtitle" >Connect your wallet, select our metavader and activate it if it's not already </p>
      </div>
</Link>
<Link class="p-4 s-main-card " to="/activate-deactivate#home">
  
      <div class="content rounded-3 p-3 p-10">
      <div className="flex align-items">
      <FaTimesCircle />
        <h1 class="s-main-card-title ml-4">Deactivate</h1>
      
        </div>
        <p class="mb-0 s-main__subtitle">Connect your wallet, select our metavader and de-activate it if it's not already </p>
      </div>
</Link>
{/* <Link class="p-4 s-main-card " to="/gallery">
  
      <div class="content rounded-3 p-3 p-10">
      <div className="flex align-items">
      <FaImages />
        <h1 class="s-main-card-title ml-4">Gallery</h1>
      
        </div>
        <p class="mb-0 s-main__subtitle">Get creative by customizing your metavaders in different background </p>
      </div>
</Link> */}
</div>

{nftCollection && nftCollection.length > 0 && walletAddress && !loading ? 
  <FadeIn delay={300}>
  <div className="crew-members-cont drop-shadow">

    <p className="crew-members-info">Your Collection</p>
    <div className="crew-members">
      
      {nftCollection.map((a, id) => (
        <div
          key={id}
          className={
          
               "crew-member"
          }
        >
          <img
            src={a.image}
          
          /> 
        </div>
      ))}
    </div>
    </div>
    </FadeIn>
: nftCollection && nftCollection.length === 0 && walletAddress && !loading ? 
<FadeIn delay={100}>
<div className="crew-members-cont drop-shadow">

<div className="crew-members s-home__opensea">
<a
href="https://opensea.io/collection/metavaders" target="_blank"
 className=""
>
  <img
  style={{width: "100%", borderRadius: "10px"}}
    src={openSea}
    alt="member-img"
  /> 
</a>

<a  href="https://opensea.io/collection/metavaders" target="_blank" className="s-home__btn--more" id="OpenSea">
  <span>See More</span>
</a> 

</div>
</div> 
</FadeIn>
: <div className="crew-members-cont drop-shadow">
<p className="crew-members-info">Connect your wallet to select Metavaders</p>
<div className="crew-members s-home__opensea">
    <button onClick={connectWalletPressed} href="https://opensebutton.io/collection/metavaders" target="_blank" className="s-home__btn--more" id="OpenSea">
      <span>{loading ? <FaSpinner className="icon-spin" /> : `Connect`}</span>
    </button> 
</div>
</div>
    }
  

{/* <div className="s-main-card-marketplace">


<div className="crew-members-cont drop-shadow">
    
<div className="crew-members s-home__opensea">
    <a
  href="https://opensea.io/collection/metavaders" target="_blank"
     className=""
    >
      <img
      style={{width: "100%", borderRadius: "10px"}}
        src={openSea}
        // onClick={() => setselectedMetavader(id)}
        alt="member-img"
      /> 
    </a>

    <a  href="https://opensea.io/collection/metavaders" target="_blank" className="s-home__btn--more" id="OpenSea">
      <span>See More</span>
    </a> 

</div>
</div>

    
  </div> */}
</section>

  )
}

export default Home;