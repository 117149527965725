

import Web3 from "web3";

const infuraProjectId = process.env.REACT_APP_infuraProjectId;
const contractABI = require("./contracts/Metavaders.json"); // Make sure to upload correct ABI
const contractAddress = "0x85A44587136C551c7FDF1f3e99dC68BDe1BA3A2b"; // Update to contract address
const invasionContractABI = require("./contracts/Invasion.json"); // Make sure to upload correct ABI
const invasionContractAddress = "0x42c29Fc77c7CB4B22E4094a8fB38de0E69Dca676"; // Update to contract address
var web3 = new Web3(`https://mainnet.infura.io/v3/${infuraProjectId}`);

export const connectWallet = async () => {
  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const obj = {
        status: "Wallet Connected",
        address: addressArray[0],
      };
      return obj;
    } catch (err) {
      window.location.reload()
      return {
        address: "",
        status: "😥 " + err.message,
      };
    }
  } else {
    return {
      address: "",
      status: (
        <span>
          <p>
            {" "}
            🦊{" "}
            <a target="_blank" rel="noreferrer" href={`https://metamask.io/download.html`}>
              You must install Metamask, a virtual Ethereum wallet, in your
              browser.
            </a>
          </p>
        </span>
      ),
    };
  }
};

export const getCurrentWalletConnected = async () => {
  if (window.ethereum) {
    try {
      const addressArray = await window.ethereum.request({
        method: "eth_accounts",
      });
      if (addressArray.length > 0) {
        return {
          address: addressArray[0],
          status: "Wallet Connected",
        };
      } else {
        return {
          address: "",
          status: '🦊 Connect to Metamask with the button ->',
        };
      }
    } catch (err) {
      return {
        address: "",
        status: "😥 " + err.message,
      };
    }
  } else {
    return {
      address: "",
      status: (
        <span>
          <p>
            {" "}
            🦊{" "}
            <a target="_blank" rel="noreferrer" href={`https://metamask.io/download.html`}>
              You must install Metamask, a virtual Ethereum wallet, in your
              browser.
            </a>
          </p>
        </span>
      ),
    };
  }
};


export const collectionInWallet = async(address) => {
  window.contract = await new web3.eth.Contract(contractABI['abi'], contractAddress);

  let collection = await window.contract.methods.collectionInWallet(address).call();
  // console.log(collection, "COLLECTION")
  return collection;
}

export const getTokenURI = async(tokenId) => {
  window.contract = await new web3.eth.Contract(contractABI['abi'], contractAddress);
  let tokenURI = await window.contract.methods.tokenURI(tokenId).call();
  return tokenURI;
}

export const changeModeMetavaders_Animetas = async(tokenId) => {
  // console.log("XX" + tokenId);
  let invasionContract = await new web3.eth.Contract(invasionContractABI['abi'], invasionContractAddress);

  const transactionParameters = {
    from: window.ethereum.selectedAddress, // must match user's active address.
    to: invasionContractAddress, // Required except during contract publications.
    data: invasionContract.methods.changeModeMetavaders_Animetas(tokenId).encodeABI(),
  };

  try {
    const txHash = await window.ethereum.request({
      method: "eth_sendTransaction",
      params: [transactionParameters],
    });
    // console.log(txHash);
    return {
      success: true,
      status: (
        <span>
        ✅ Check out the status of transaction on Etherscan: {" "}
        <a target="_blank" rel="noreferrer" href={`https://etherscan.io/tx/` + txHash}>
          here
        </a>
      </span>
      )
    };  
  } catch (error) {
    return {
      success: false,
      status: "😥 Something went wrong: " + error.message,
    };    
  }
}

export const changeModeMetavaders_Normal = async(tokenId) => {
  // console.log(tokenId, "TOKEN ID")
  let invasionContract = await new web3.eth.Contract(invasionContractABI['abi'], invasionContractAddress);

  const transactionParameters = {
    from: window.ethereum.selectedAddress, // must match user's active address.
    to: invasionContractAddress, // Required except during contract publications.
    data: invasionContract.methods.changeModeMetavaders_Normal(tokenId).encodeABI(),
  };

  try {
    const txHash = await window.ethereum.request({
      method: "eth_sendTransaction",
      params: [transactionParameters],
    });
    // console.log(txHash);
    return {
      success: true,
      status: (
        <span>
        ✅ Check out the status of transaction on Etherscan: {" "}
        <a target="_blank" rel="noreferrer" href={`https://etherscan.io/tx/` + txHash}>
          here
        </a>
      </span>
      )
    };  
  } catch (error) {
    return {
      success: false,
      status: "😥 Something went wrong: " + error.message,
    };    
  }
}

export const getInvaderBalance = async() => {
  window.contract = await new web3.eth.Contract(invasionContractABI['abi'], invasionContractAddress);
  let invaderBalance = await window.contract.methods.getInvaderBalance().call({from: window.ethereum.selectedAddress});
  console.log("Invader Balance: " + invaderBalance)
  return invaderBalance;
}