import React, {useState} from "react";

import "./index.css"
import Activate from "../Activate";
import Deactivate from "../Deactivate";
import {useLocation} from "react-router-dom"
import { useEffect } from "react";

const Main = ({nftCollection, onUpdateNftCollection,
  onUpdateWallet,
  walletAddress,
  setIsLoading,
  setStatusState,
  loading,
  status,
   updateDrawer}) => {
  const [toggle, setToggle] = useState(true)

  const location = useLocation()
 
useEffect(() => {
if(location && location.hash){
  setToggle(false)
}
},[])
  return (
<div>


{toggle ? <Activate 
  setIsLoading={setIsLoading} 
  loading={loading} onUpdateWallet={onUpdateWallet}
updateDrawer={updateDrawer}   
 status={status}   
  setStatusState={setStatusState} 
     onUpdateNftCollection={onUpdateNftCollection} 
     walletAddress={walletAddress} 
     toggle={toggle}
      setToggle={setToggle} 
      loading={loading}
      nftCollection={nftCollection} /> : 



<Deactivate 
 walletAddress={walletAddress} 
setToggle={setToggle} 
setIsLoading={setIsLoading}
toggle={toggle} 
status={status}
loading={loading}
nftCollection={nftCollection} 
onUpdateWallet={onUpdateWallet}
updateDrawer={updateDrawer} 
setStatusState={setStatusState}  
onUpdateNftCollection={onUpdateNftCollection}
/>}
  
</div>
  )
}

export default Main;