import React, {useState,createContext} from "react";
import "./App.css";
import Activate from "./pages/Activate";
import {BrowserRouter as Router, Switch, Route, Link} from "react-router-dom";
import Deactivate from "./pages/Deactivate";
import NavBar from "./components/Navbar";
import Sidebar from "./components/Sidebar";
import 'react-pro-sidebar/dist/css/styles.css';
import "../src/components/Sidebar/index.css"
import Gallery from "./pages/Gallery";
import Home from "./pages/Home";
import Main from "./pages/Main/Main";
import Collab from "./pages/Collab";
import Starship from "./pages/Starship";


function App() {
  const [collapsed, setCollapsed] = useState(false);
  const Context = createContext('Default Value');
  const [image, setImage] = useState(true);
  const [toggled, setToggled] = useState(false);
  const [drawer, setDrawer] = useState(false)
  const [nftCollection, setNftCollection] = useState([]);
  const [spacePunkCollection, setSpacePunkCollection] = useState([])
  const [walletAddress, setWallet] = useState()
  const [status, setStatus] = useState()
  const [loading, setLoading] = useState(false)
  const [location, setLocation] = useState("")
 

  const handleCollapsedChange = (checked) => {
    setCollapsed(checked);
  };

 
  const handleImageChange = (checked) => {
    setImage(checked);
  };

  const handleToggleSidebar = (value) => {
    setToggled(value);
  };


  return (
    <div className={`app App ${toggled ? 'toggled' : ''}`}>

    <Router>
     
  
      <Sidebar image={image}
        collapsed={collapsed}
        drawer={drawer}
        setPath={setLocation}
        handleUpdateWallet={setWallet}
        toggled={toggled}
        handleToggleSidebar={handleToggleSidebar}  updateDrawer={setDrawer}/>
            
    
      <main className={`s-main ${location === "/starship" && `s-starship`}`}>
       <NavBar   
           onUpdateSpacePunkCollection={setSpacePunkCollection}
       onUpdateNftCollection={setNftCollection}
        image={image}
     
        walletAddress={walletAddress}
        toggled={toggled}
        collapsed={collapsed}
        status={status}
        setIsLoading={setLoading}
        loading={loading}
         setStatusState={setStatus}
        onUpdateWallet={setWallet}
        updateDrawer={setDrawer}
        handleToggleSidebar={handleToggleSidebar}
        handleCollapsedChange={handleCollapsedChange}

        handleImageChange={handleImageChange} />

    <Switch>
    <Route exact path="/">
     <Home  setIsLoading={setLoading} loading={loading}  setStatusState={setStatus} status={status} onUpdateWallet={setWallet}
        updateDrawer={setDrawer}        onUpdateNftCollection={setNftCollection} walletAddress={walletAddress} nftCollection={nftCollection}/>
      </Route>
      <Route exact path="/activate-deactivate">
     <Main  setIsLoading={setLoading} loading={loading}  setStatusState={setStatus} status={status} onUpdateWallet={setWallet}
        updateDrawer={setDrawer}        onUpdateNftCollection={setNftCollection} walletAddress={walletAddress} nftCollection={nftCollection}/>
      </Route>
    
    <Route exact path="/gallery">
        <Gallery setIsLoading={setLoading} loading={loading}  setStatusState={setStatus} status={status} onUpdateWallet={setWallet}
        updateDrawer={setDrawer}        onUpdateNftCollection={setNftCollection} walletAddress={walletAddress} nftCollection={nftCollection} />
      </Route>  
       <Route exact path="/collab">
        <Collab spacePunkCollection={spacePunkCollection}        setIsLoading={setLoading} loading={loading}  setStatusState={setStatus} status={status} onUpdateWallet={setWallet}
        updateDrawer={setDrawer}        onUpdateNftCollection={setNftCollection} walletAddress={walletAddress} nftCollection={nftCollection} />
      </Route> 
      <Route>
        <Starship  exact path="/upgrade-starship" setIsLoading={setLoading} loading={loading}  setStatusState={setStatus} status={status} onUpdateWallet={setWallet}
        updateDrawer={setDrawer}        onUpdateNftCollection={setNftCollection} walletAddress={walletAddress} nftCollection={nftCollection} />
      </Route>
    </Switch>
    </main>
    </Router>
    
    </div>
  );
}

export default App;
