import React, {useState} from "react";
import Popup from "reactjs-popup";
import "./style.css";
import loading_01 from "../../assets/loading_01.gif";
import Toast from 'light-toast';
//Web3
import { 
  changeModeMetavaders_Animetas, changeModeMetavaders_Normal
} from "../../web3/interact.js";

export const Modal = ({ trigger, action, selectedMetavader="N/A", invasionTarget="", nftMetadata=""}) => {
  let text;
  let active_imageURL;
  let mode;
  let buttonLabel = "Cancel";
  const [activateState, setActivateState] = useState(1);
  const [status, setStatus] = useState("");
  

  let imageURL = nftMetadata.image;


  if (selectedMetavader=="N/A") {
    text = "Please first select a Metavader";
  } else if (invasionTarget==="" && action !== "deactivate") {
    text = "Please also select an invasion target";
  } else if(action === "activate") {
    text = `This will be your new Metavader image, are you sure you want to ${action} your Metavader?`
    mode = imageURL.charAt(imageURL.length-5);
    // mode = "1" // For testing purposes, commment out on live site
    // console.log("Mode " + mode);
    if (isNaN(mode)) {
      text = `Your Metavader has already been activated`;
    
    } else {
      active_imageURL = imageURL.replace(".png","A.png");
      buttonLabel =  `Activate` 
    }
  } else if(action === "deactivate") {
    text = `This will be your new Metavader image, are you sure you want to ${action} your Metavader?`
    mode = imageURL && imageURL.charAt(imageURL.length-5);

    if (isNaN(mode)) {
      active_imageURL = imageURL && imageURL.replace("A.png",".png");
      buttonLabel =  `Deactivate` 
    } else {
      text = `Your Metavader hasn't been activated yet`;
    }

  }



  async function activation() {
    //Send transaction to activate
    setActivateState(2);
    const { success, status } = await changeModeMetavaders_Animetas(nftMetadata.id);
    if (success) {
      setActivateState(3);
      setStatus(status);
      Toast.success(status, 8000, () => {
        // do something after the toast disappears
      });
      // console.log(status);
    } else {
      setActivateState(0);
      setStatus(status);
      Toast.fail(status, 8000, () => {
        // do something after the toast disappears
      });
      // console.log(status);
    }
  }

  async function deactivation() {
    //Send transaction to activate
    setActivateState(2);
    const { success, status } = await changeModeMetavaders_Normal(nftMetadata.id);
    if (success) {
      setActivateState(3);
      setStatus(status);
      Toast.success(status, 8000, () => {
        // do something after the toast disappears
      });
      // console.log(status);
    } else {
      setActivateState(0);
      setStatus(status);
      Toast.fail(status, 8000, () => {
        // do something after the toast disappears
      });
      // console.log(status);
    }
  }


  return (
  <Popup trigger={trigger} modal nested>
    {(close) => (
      <div className="modal">
        <button className="close" onClick={close}>
          &times;
        </button>
        <div className="header"> Please Confirm {action === "activate" ? `Activation` : `Deactivation`} </div>
        <div className="content" style={{display: (activateState===1)?  "inherit" : "none"}}>
          <p className="activation_text">{text}</p>
          {(active_imageURL) ? <img className="activation_image" src={active_imageURL} /> : "" }
        </div>
        <div className="content" style={{display: (activateState===2 || activateState==0)?  "inherit" : "none"}}>
          <p className="activation_text">Please confirm your Metamask transaction</p>
          <img className="activation_image" src={loading_01} />
          <p className="status" style={{ color: "red" }}>{status}</p>
        </div>
        <div className="content" style={{display: (activateState===3)?  "inherit" : "none"}}>
          <p className="activation_text">Sucessfully {action === "activate" ? `Activated` : `Deactivated`} Metavader...</p>
          <img className="activation_image" src={active_imageURL} />
          <p className="status" style={{ color: "#2860F6" }}>{status}</p>
        </div>
        <div className="actions">
          <button
            className="button"
            onClick={() => {
              if (buttonLabel ==='Activate' ) {
                activation();
              } else if(buttonLabel === "Deactivate"){
                deactivation();
              }{
                close();
              }
            }}
            style={{display: (activateState==2)?  "none" : "inline block"}}
          >
            {buttonLabel}
          </button>
        </div>
      </div>
    )}
  </Popup>
)
};
export default Modal;
