import React from 'react';
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from 'react-pro-sidebar';
import { FaTachometerAlt, FaGem, FaList, FaGithub, FaRegLaughWink, FaHeart, FaCheckCircle, FaTimesCircle, FaImages, FaHome, FaTimes, FaSpaceShuttle, FaUserSecret, FaUserSlash, FaUsersCog } from 'react-icons/fa';
import './index.css';
import 'react-pro-sidebar/dist/css/styles.css';
import { useLocation, Link } from 'react-router-dom';
import Footer from '../Footer';
import { useEffect } from 'react';


const Sidebar = ({  collapsed, drawer, setPath, updateDrawer, toggled, handleToggleSidebar }) => {
    const location = useLocation();
    console.log(location)
 setPath(location && location.pathname)
  return (
    <ProSidebar
      image={false}
      collapsed={collapsed}
      toggled={toggled}
      breakPoint={drawer ? null : `md`}
      onToggle={handleToggleSidebar}
      style={drawer ? {position: "absolute"} :null}
      className={`${location && location.pathname === "/starship" && `s-sidebar`}`}
    >
      <SidebarHeader>
        <div
          style={{
            padding: '24px',
            textTransform: 'uppercase',
            fontWeight: 'bold',
            fontSize: 14,
            letterSpacing: '1px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            position: "relative"
          }}
          className="pro-sidebar-title"
        >
       Metavaders dApp- Beta
        </div>
<div className="s-icon-times displayDesktop" onClick={() => updateDrawer(false)}>
        <FaTimes />
        </div>
      </SidebarHeader>
      <SidebarContent>
        <Menu iconShape="circle">   
          <MenuItem   
            icon={<FaHome />}       
          >
           <Link to="/">
        Home
        </Link>
          </MenuItem>
           <MenuItem   icon={<FaCheckCircle />}><Link to="/activate-deactivate"> Activate/De-activate     </Link></MenuItem>         
        </Menu>
         {/* <Menu iconShape="circle">
        <MenuItem icon={<FaImages />}>  <Link to="/gallery">Gallery   </Link> </MenuItem>
        </Menu>  */}
        <Menu iconShape="circle">
        <MenuItem icon={<FaUsersCog />}>  <Link to="/collab">Collab Uniforms   </Link> </MenuItem>
        </Menu> 
        {/* <Menu iconShape="circle">
        <MenuItem icon={<FaSpaceShuttle />}>  <Link to="/upgrade-starship">Upgrade Starship   </Link> </MenuItem>
        </Menu>  */}
      </SidebarContent>
      <SidebarFooter style={{ textAlign: 'center' }}>
      {/* <Link to="/starship" className="s-sidebar__bottomBtn">
          <p>Upgrade to Starship</p> 
      </Link> */}
        <div
          className="sidebar-btn-wrapper"
          style={{
            padding: '20px 24px',
          }}
        >
          <a className="s-link" href="https://metavaders.io/">
      Return To Site
         </a>
          <Footer />
        </div>
      </SidebarFooter>
    </ProSidebar>
  );
};

export default Sidebar;