
 import suit1 from "../../assets/armour 1 - green.png";
 import suit2 from "../../assets/armour 1 - purple.png";
 import suit3 from "../../assets/armour 1 - red.png";


 import suit4 from "../../assets/armour 1 - yellow.png";
 import suit5 from "../../assets/armour 2 - blue.png";
import suit6 from "../../assets/armour 2 - gray.png";
import suit7 from "../../assets/armour 2 - green.png";
import suit8 from "../../assets/armour 2 - pink.png";
import suit9 from "../../assets/armour 2 - red.png";
import suit10 from "../../assets/armour1-gray.png";

const bgImages = [
suit1,suit2,suit3, suit4,suit5,suit6, suit7, suit8, suit9, suit10
]
export default bgImages 