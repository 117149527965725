import React, { useEffect, useState, loadDataOnlyOnce } from "react";
import NavBar from "../../components/Navbar";
import animetasTarget from "../../assets/animetas.png";
import Toggle from 'react-toggle'
import Footer from "../../components/Footer";
import "reactjs-popup/dist/index.css";
import openSea from "../../assets/opensea.png";
import FadeIn from "react-fade-in";
import "./index.css";
import Modal from "../../components/Modal";
import { 
  connectWallet, 
  getCurrentWalletConnected, 
  collectionInWallet,
  getTokenURI,
  getInvaderBalance,   
} from "../../web3/interact";
import { FaSpinner, FaQuestionCircle } from "react-icons/fa";
import ReactTooltip from "react-tooltip";
import Toast from 'light-toast';

function Activate({nftCollection, toggle,
onUpdateWallet,
walletAddress,
setStatusState,
loading, setIsLoading,
  setToggle}) {
  const [selectedMetavader, setselectedMetavader] = useState();
  const [invasionTarget, setInvasionTarget] = useState();
  const [invasionBalance, setInvasionBalance] = useState();

  useEffect(async () => {
    const { address, status } = await getCurrentWalletConnected();
   
    onUpdateWallet(address);
    setStatusState(status);

    if (address != "") {
      setIsLoading(false)
    }

  }, []);
  


  const connectWalletPressed = async () => {
    setIsLoading(true)
    const walletResponse = await connectWallet();
    setStatusState(walletResponse.status);
    onUpdateWallet(walletResponse.address);
    setIsLoading(false)
    window.location.reload()
  };


const onInvasionSelect = async (value) => {
  setInvasionTarget(value)
 const result = await getInvaderBalance()

  setInvasionBalance(result)
 
}

const handleDisabledButton = () => {

  if(invasionTarget && invasionBalance && invasionBalance === "0"){
    Toast.fail("You do not own any animetas", 4000, () => {
     
    });
  }
}
  return (
    <section>
    <div className="container-wrap s-home" >
   
      <div className="divider" ></div>

     
      <div className="s-home__toggle">
  <Toggle
    defaultChecked={toggle}
    icons={false}
    onChange={() => setToggle(!toggle)} />
    <div className="topmargin">
<p>Click here to toggle between Activate/Deactivate page</p>
      </div>
</div>
     
      <FadeIn delay={300}>
    
      <h2 className="new-section-text">ACTIVATE</h2>
      <p className="s-home__label">To activate please do the following:</p>
      <ol className="activate-ul">
        <li>Connect your wallet (if it is not yet connected).</li>
        <li>Select the Metavader you wish to activate.</li>
        <li>Select the invasion target (make sure you own a metavader from the target metaverse).</li>
        <li>Click "Activate" in which a pop up should appear.</li>
        <li>Check the look of the activate Metavader NFT. If you are fine with it click "Activate"</li>
        <li>Your Metamask wallet should ask you to confirm a small gas transaction. Confirm that.</li>
        <li>Wait for your transaction to complete. (You can then check the transaction on Etherscan).</li>
        <li>Once the transaction is complete you should be able to refresh this page and see your activate Metavader!</li>
        <li>Note: You will also need to refresh the metadata on Opensea to make sure it shows up there too.</li>
      </ol>

     
      {nftCollection && nftCollection.length > 0 && walletAddress && !loading ? 
      <FadeIn delay={300}>
      <div className="crew-members-cont drop-shadow">
    
        <p className="crew-members-info">Select Metavader</p>
        <div className="crew-members">
          
          {nftCollection.map((a, id) => (
            <div
              key={id}
              className={
                selectedMetavader == id
                  ? "crew-member selected"
                  : "crew-member"
              }
            >
              <img
                src={a.image}
                onClick={() => setselectedMetavader(id)}
              /> 
            </div>
          ))}
        </div>
        </div>
        </FadeIn>
: nftCollection && nftCollection.length === 0 && walletAddress && !loading ? 
<FadeIn delay={100}>
<div className="crew-members-cont drop-shadow">
    
<div className="crew-members s-home__opensea">
    <a
  href="https://opensea.io/collection/metavaders" target="_blank"
     className=""
    >
      <img
      style={{width: "100%", borderRadius: "10px"}}
        src={openSea}
        alt="member-img"
      /> 
    </a>

    <a  href="https://opensea.io/collection/metavaders" target="_blank" className="s-home__btn--more" id="OpenSea">
      <span>See More</span>
    </a> 

</div>
</div> 
</FadeIn>
: <div className="crew-members-cont drop-shadow">
    <p className="crew-members-info">Connect your wallet to select Metavaders</p>
    <div className="crew-members s-home__opensea">
        <button onClick={connectWalletPressed} href="https://opensebutton.io/collection/metavaders" target="_blank" className="s-home__btn--more" id="OpenSea">
          <span>{loading ? <FaSpinner className="icon-spin" /> : `Connect`}</span>
        </button> 
    </div>
    </div>
        }
      
    
      <div className="invasion-target-cont drop-shadow">
        <p className="invasion-target-info">Select invasion target</p>
        <div className="invasion-targets">
        <div
            className={
              invasionTarget == "NFT_animetas"
                ? "invasion-target selected"
                : "invasion-target"
            }
          >
            <img
              src={animetasTarget}
              alt="target-img"
              onClick={() =>
              onInvasionSelect("NFT_animetas")
              
              }
            />
          </div>
      
          {/* <div
            className={
              invasionTarget == "NFT_seven"
                ? "invasion-target selected"
                : "invasion-target"
            }
          >
            <img
              src={seventarget}
              alt="target-img"
              onClick={() =>
                setInvasionTarget("NFT_seven")
              }
            />
          </div>
          <div
            className="invasion-target"
            className={
              invasionTarget == "NFT_on1"
                ? "invasion-target selected"
                : "invasion-target"
            }
          >
            <img
              src={forcetarget}
              onClick={() =>
                setInvasionTarget("NFT_on1")
              }
              alt="target-img"
            />
          </div> */}
        </div>
        {invasionTarget && invasionBalance && invasionBalance === "0" &&  <div className="topmargin">
<h5>You do not own any animetas</h5>
      </div>}
      </div>

      <div className="activate-btn-container">
       {
         invasionTarget && invasionBalance && invasionBalance === "0" ?    <button className={`activate-page-btn s-home__btn--second ${invasionTarget && invasionBalance && invasionBalance === "0" && `disabled`}`} id="walletButton" onClick={() => handleDisabledButton()} >
         <span>ACTIVATE</span>
       </button> :  <Modal
          trigger={
            <button className={`activate-page-btn s-home__btn--second `} id="walletButton" >
              <span>ACTIVATE</span>
            </button>
          }
          action="activate"
          selectedMetavader = {selectedMetavader}
          invasionTarget = {invasionTarget}
          nftMetadata = {nftCollection && nftCollection[selectedMetavader]}
        />
       }
       
      </div>
      <Footer />
      </FadeIn>
    </div>
    <ReactTooltip />
    </section>
  );
}

export default Activate;
