import React, {useState, useEffect} from "react";
import logo from "../../assets/logoN1.png";
import {Link} from "react-router-dom";
import {FaBars, FaTimes} from "react-icons/fa";
import Switch from "react-switch";
import { 
  connectWallet, 
  getCurrentWalletConnected, 
  collectionInWallet,

  getTokenURI,
} from "../../web3/interact";
import error_placeholder from "../../assets/error_placeholder.png";


import './index.css';
import { collectionSpacePunkInWallet,getTokenURI_SpacePunks, convertIPFSToHTML, tokenOfOwnerByIndex } from "../../web3/spacePunksExample";


export const NavBar = ({ 
onUpdateNftCollection,
onUpdateWallet,
walletAddress,
status,
setIsLoading,
setLoading,
onUpdateSpacePunkCollection,
setStatusState,
 updateDrawer}) => {
  const [drawer, setDrawer] = useState(false)
  const [showNotif, setShowNotif] = useState(true)



  useEffect(async () => {
    setIsLoading(true)
    const { address, status } = await getCurrentWalletConnected();
    onUpdateWallet(address);
    setStatusState(status);
    if (address !== "") {
      getCollectionInWallet(address);
      getSpacePunkInWallet(address)
      setIsLoading(false)
    } else {
      setIsLoading(false)
    }
    addWalletListener();
 
  }, [walletAddress]);




  {/* METAVADERS LOGIC */}
  const getCollectionInWallet = async (address) =>{
    if(window.ethereum) {
      let collection = await collectionInWallet(address);
      // Get NFT Metadata
      let collectionMetadata = [];
  

      for(let i=0; i < collection.length; i++) {
        
        let url = await getTokenURI(collection[i]);
      
        let metadata = await getMetadata(url);
      
        if ("image" in metadata) {
       
          metadata['id'] = collection[i];
          collectionMetadata.push(metadata);
       
        } else {
        
          metadata['id'] = collection[i];
          metadata['image'] = error_placeholder;
   
        }
      }
      onUpdateNftCollection(collectionMetadata)
      setTimeout(() => {
        if(collectionMetadata){
          setIsLoading(false)
        }
      }, 200);
  
    }
  };


  {/* SPACE PUNK LOGIC */}
  const getSpacePunkInWallet = async (address) => {

    if(window.ethereum) {
      let items = await collectionSpacePunkInWallet(address)  

      let spacePunkCollection = [];

      for(let i=0; i < items; i++) {
        let spacePunks = await tokenOfOwnerByIndex(address, i)
        let url = await getTokenURI_SpacePunks(spacePunks);
      let  metadata = convertIPFSToHTML(url);
        if (metadata) {
fetch(metadata)
.then(res => res.json())
.then((out) => {
  console.log('Checkout this JSON! ', spacePunkCollection.push(out))
})
.catch(err => { throw err });
        } 
      }
      onUpdateSpacePunkCollection(spacePunkCollection)
    }
  }

  

  async function getMetadata (metadataUrl) {
    let result = await fetch_json(metadataUrl);
    return result;
  }

  function fetch_json(url) {
    return fetch(url).then((response) => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('Something went wrong');
      }
    })
    .then((responseJson) => {
      return responseJson
    })
    .catch((error) => {
      console.log(error);
      return error
    });
  }


  function addWalletListener() {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          onUpdateWallet(accounts[0]);
          setStatusState("");
          window.location.reload()
        } else {
          onUpdateWallet("");
          setStatusState(
            '🦊 Connect to Metamask with the button ->'
          );
        }
      });
    } else {
      setStatusState(
        <p>
          {" "}
          🦊{" "}
          <a
            target="_blank"
            rel="noreferrer"
            href={`https://metamask.io/download.html`}
          >
            You must install Metamask, a virtual Ethereum wallet, in your
            browser.
          </a>
        </p>
      );
    }
  }

  const connectWalletPressed = async () => {
    setIsLoading(true)
    const walletResponse = await connectWallet();
    setStatusState(walletResponse.status);
    onUpdateWallet(walletResponse.address);
    getCollectionInWallet(walletResponse.address);
    getSpacePunkInWallet(walletResponse.address)
    setIsLoading(false)
    setTimeout(() => {
      window.location.reload()
    }, 200);
  };

  useEffect(() => {

    if(localStorage.getItem("showNotif")){
      setShowNotif(false)
    }

  },[])

  const handleShowNotif = (value) => {
    setShowNotif(value)
    localStorage.setItem("showNotif", true)
  }


  const showNotification = () => {
    return (
    <div className="s-notification">
    <p> For better performance, you should use a laptop. </p>
    <div className="s-close"  onClick={() => handleShowNotif(false)}>
      <FaTimes />
    </div>
    </div>
    )
  }

  return (
    <div className="navbar ">

  {showNotif && showNotification()}
<div className="s-icon" onClick={() => updateDrawer(true)}>
<FaBars />
</div>
          <a href="/" className="">
        <img src={logo} className="logo" alt="logo" />
      </a>
        <p id="status" style={ status && status === "Wallet Connected" ? { color: "green" }: {color: "red"}}>
        {status}
      </p>
    

      <div className="connect-btn-container">
        <button
          className="connect-page-btn s-home__btn--first"
          id="walletButton"
          onClick={connectWalletPressed}
        >
          {walletAddress && walletAddress.length > 0 ? (
            "Connected: " +
            String(walletAddress).substring(0, 6) +
            "..." +
            String(walletAddress).substring(38)
          ) : (
            <span>🦊 Connect Wallet</span>
          )}
        </button>
      </div>
     

    </div>
  );
};
export default NavBar;
