
import Web3 from "web3";


const spacePunksContractABI = require("./contracts/SpacePunks.json"); // Make sure to upload correct ABI
const spacePunksContractAddress = "0x45db714f24f5a313569c41683047f1d49e78ba07"; // Update to contract address
const web3 = new Web3(`https://mainnet.infura.io/v3/6e3fdc31eb5b4621b3f03c6ebad9dc35`);





export const collectionSpacePunkInWallet = async(address) => {
    window.contract = await new web3.eth.Contract(spacePunksContractABI['abi'], spacePunksContractAddress);
  
    let collection = await window.contract.methods.balanceOf(address).call();
    console.log(collection, "COLLECTION")
    // console.log(collection, "COLLECTION")
    return collection;
  } 

  export const tokenOfOwnerByIndex = async(address, index) => {
    window.contract = await new web3.eth.Contract(spacePunksContractABI['abi'], spacePunksContractAddress);
  
    let collection = await window.contract.methods.tokenOfOwnerByIndex(address, index).call();
    // console.log(collection, "COLLECTION")
    return collection;
  }
  
  

export const getTokenURI_SpacePunks = async(tokenId) => {
   let contract = await new web3.eth.Contract(spacePunksContractABI['abi'], spacePunksContractAddress);
    let tokenURI = await contract.methods.tokenURI(tokenId).call();
    return tokenURI;
}

export const  convertIPFSToHTML = (ipfs_link) => {
    return ipfs_link.replace('ipfs://', 'https://gateway.pinata.cloud/ipfs/')
}

export const  getImageURL = (link) => {
    
    return 
}

// const main = async (tokenId) => {
//     console.log('Running up to: ' + tokenId);
//     let result = await getTokenURI_SpacePunks(tokenId);
//     result = convertIPFSToHTML(result)['external_url'];
//     // do something else here after firstFunction completes
//     console.log(result)
//   }

//   main(1)