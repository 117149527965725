
 import city1 from "./assets/city1.png";
 import city2 from "./assets/city2.png";
// import city3 from "./assets/city3.png";


// import temple1 from "./assets/temple1.gif";
 import temple2 from "./assets/temple2.png";
// import temple3 from "./assets/temple3.png";

import desert1 from "./assets/desert1.png";
import desert2 from "./assets/desert2.png";
 import desert3 from "./assets/desert3.png";


const bgImages = [
   city2, temple2, desert3,
]
export default bgImages 