import React, { useEffect, useState, loadDataOnlyOnce, createRef } from "react";
import NavBar from "../../components/Navbar";
import animetasTarget from "../../assets/animetas.png";
import Footer from "../../components/Footer";
import "reactjs-popup/dist/index.css";
import error_placeholder from "../../assets/error_placeholder.png";
import { useScreenshot, createFileName } from "use-react-screenshot";
import "./index.css";
import avatar from "./testImages";
import bgImages from "./bgImages";
import openSea from "../../assets/opensea.png";
import imageToBase64 from 'image-to-base64/browser';
import { FaSpinner, FaQuestionCircle } from "react-icons/fa";
import { 
  connectWallet, 
  getCurrentWalletConnected, 
  collectionInWallet,
  getTokenURI,
  getInvaderBalance,   
} from "../../web3/interact";
import FadeIn from "react-fade-in";

function Gallery({nftCollection, toggle,
  onUpdateWallet,
  walletAddress,
  setStatusState,
  loading, setIsLoading,
    setToggle}) {


  const ref = createRef(null);
  const [width, setWidth] = useState(300);


const [formatedImages, setFormatedImages] = useState([])
const [showNotif, setShowNotif] = useState(false)
  
  const [step, setStep] = useState(1)
  const [selectedBgImage, setSelectedBgImage] = useState()

  const [selectedMetavaders, setSelectedMetavaders] = useState([])


  const getImage = () => takeScreenShot(ref.current);


  useEffect(()=> {
    if(nftCollection && nftCollection.length > 0){
      setShowNotif(false)
      nftCollection.map(item => {
  var xhr = new XMLHttpRequest();       
  xhr.open("GET", item.image, true); 
  xhr.responseType = "blob";
  xhr.onload = function (e) {
       
          var reader = new FileReader();
          reader.onload = function(event) {
             var res = event.target.result;
             console.log(res);
             setFormatedImages([...formatedImages, res])
          }
          var file = this.response;
          reader.readAsDataURL(file)
  };
  xhr.send()
      })
    } else {
      setTimeout(() => {
        setShowNotif(true)
      }, 200);
    }
  },[nftCollection])



const onSelectBgImage = (img)=> {
if(selectedBgImage){
  setStep(2)
}
}

useEffect(async () => {
  const { address, status } = await getCurrentWalletConnected();
 
  onUpdateWallet(address);
  setStatusState(status);

  if (address != "") {
    setIsLoading(false)
  }

}, []);



  

  const onChangeMetavaders = (img) => {
      
        let allMetavaders 
    if(selectedMetavaders && selectedMetavaders.length > 0 && selectedMetavaders.includes(img)){
            
        allMetavaders = selectedMetavaders.filter(image => image !== img)
            setSelectedMetavaders(allMetavaders)
    } else {
        setSelectedMetavaders([...selectedMetavaders, img])
    }
  
  }


  const imageRef = createRef(null);
  const [image, takeScreenShot] = useScreenshot({
    type: "image/jpeg",
    quality: 1.0
  });

  const download = (image, { name = "metavaders-image", extension = "png" } = {}) => {
    const a = document.createElement("a");
    a.href = image;
    a.download = createFileName(extension, name);
    a.click();
  };

  const downloadScreenshot = () =>{
  

    setTimeout(() => {
      takeScreenShot(ref.current).then(download);
    }, 200);
  } 

 
  const connectWalletPressed = async () => {
    setIsLoading(true)
    const walletResponse = await connectWallet();
    setStatusState(walletResponse.status);
    onUpdateWallet(walletResponse.address);
    setIsLoading(false)
    window.location.reload()
  };

  return (
    <FadeIn delay={150}>
    <div className="container-wrap s-gallery">
   
      <div className="divider"></div>

      <div className="topmargin" ></div>
      <div className="s-gallery__topSection">
      <div>
      <h2 className="new-section-text">CUSTOMIZE</h2>

      <ul className="activate-ul">
        <li>Connect your wallet</li>
        <li>Select a background</li>
        <li>Select Metavader to add in picture</li>
        <li>Save & download </li>
        
      </ul>
      </div>
  
      
      </div>

   
    
      {/* <div className="crew-members-cont drop-shadow">
        <p className="crew-members-info">Select Metavader</p>
        <div className="crew-members">
          
          {nftCollection.map((a, id) => (
            <div
              key={id}
              className={
                selectedMetavader == id
                  ? "crew-member selected"
                  : "crew-member"
              }
            >
              <img
                src={a}
                onClick={() => setselectedMetavader(id)}
                alt="member-img"
              /> 
            </div>
          ))}
        </div>
      </div> */}

      <div className="s-gallery__container">

    
     
{nftCollection && nftCollection.length > 0 && walletAddress && !loading && step === 1 ? 
      <FadeIn delay={300}>
     <div className="s-gallery__card">
    <p className="crew-members-info">Select Background Image</p>
    <div className="s-gallery__images--background">
{bgImages && bgImages.map((img, index) => (<img key={index} className={`${selectedBgImage === img && `s-gallery__selected`}`} onClick={() => setSelectedBgImage(img)} key={index} src={img}/>))}
</div>

    </div>
        </FadeIn>
: nftCollection && nftCollection.length === 0 && walletAddress && !loading  && step === 1  ? 
<FadeIn delay={100}>
<div className="crew-members-cont ">
    
<div className="crew-members s-home__opensea">
    <a
  href="https://opensea.io/collection/metavaders" target="_blank"
     className=""
    >
      <img
      style={{width: "100%", borderRadius: "10px"}}
        src={openSea}
        alt="member-img"
      /> 
    </a>

    <a  href="https://opensea.io/collection/metavaders" target="_blank" className="s-home__btn--more" id="OpenSea">
      <span>See More</span>
    </a> 

</div>
</div> 
</FadeIn>
: <div className="s-gallery__card">
{showNotif ? <p className="crew-members-info" style={{color: "orange"}}>You currently own no metavaders</p> :  <p className="crew-members-info">Add Metavader to background image</p>}

<div className="s-gallery__images--metavaders">
{formatedImages && formatedImages.length > 0 && formatedImages.map((item, index) => (<img key={index} className={`${selectedMetavaders.includes(item) && `s-gallery__selected`}`} onClick={() => onChangeMetavaders(item)} src={item}/>))}
</div>


<div ref={ref} className="s-gallery__images--combined">
<img src={selectedBgImage} className="s-gallery__images--top"/>
{selectedMetavaders && selectedMetavaders.length > 0 && selectedMetavaders.map((item, index) => <img  className="s-gallery__images--bottom" style={index === 0 ? {left: "0"} : index === 2 ? {right: "0"} : null} key={index} src={item} />)}
</div>

</div>
        }
      




        <div className="s-gallery__btn">
        {step !== 1 && 
          <button className="s-gallery__btn--back" onClick={() => step === 3 ? setStep(2) : step === 2 ? setStep(1) : null}>
                Back
          </button>
        }
          
{

}

{step === 1 &&  <button disabled={showNotif} style={nftCollection && nftCollection.length === 0 ? {opacity: "0.8"} : null}  className="s-gallery__btn--next" onClick={() => onSelectBgImage()  }>
Next
</button> }
         
          
          {step !== 1 &&   <button className="s-gallery__btn--download" onClick={downloadScreenshot}>
                Download
            </button>}
          
          
        </div>
  
         
       
      </div>
     

     

      <Footer showFooter="displayDesktop"/>
    </div>
    </FadeIn>
  );
}

export default Gallery;
