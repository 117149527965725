import React, { useEffect, useState, createRef } from "react";
import ReactReveal from 'react-reveal/Fade'
import NavBar from "../../components/Navbar";
import animetasTarget from "../../assets/animetas.png";
import Footer from "../../components/Footer";
import "reactjs-popup/dist/index.css";
import error_placeholder from "../../assets/error_placeholder.png";
import { useScreenshot, createFileName } from "use-react-screenshot";
import "./index.css";
import avatar from "../../assets/avatar.png";
import bgImages from "./bgImages";
import spaceChunkLogo from '../../assets/spacepunk-logo.png';
import FadeIn from "react-fade-in";
import spacePunksContractABI from "../../web3/contracts/SpacePunks.json"// Make sure to upload correct ABI
import { getCurrentWalletConnected } from "../../web3/interact";
import Toast from 'light-toast';
import { FaSpinner } from "react-icons/fa";

function Collab({nftCollection, 
  spacePunkCollection,
  toggle,
  onUpdateWallet,
  walletAddress,
  setStatusState,
  loading, 
  setIsLoading,
    setToggle}) {



  const ref = createRef(null);
  const [width, setWidth] = useState(300);
      const [loadingDownload, setLoadingDownload] = useState(false)
  const [showNotif, setShowNotif] = useState(false)
const [formatedImages, setFormatedImages] = useState([])
  const [transformImage, setTransformImage] = useState(false)
  const [step, setStep] = useState(0)
  const [selectedBgImage, setSelectedBgImage] = useState()

  const [selectedMetavaders, setSelectedMetavaders] = useState([])


  const getImage = () => 
    
    takeScreenShot(ref.current)

// Transforming image to base64
  useEffect(()=> {
    if(spacePunkCollection && spacePunkCollection.length > 0 && transformImage){
      setShowNotif(false)
      spacePunkCollection.map(item => {
  var xhr = new XMLHttpRequest();       
  xhr.open("GET", item.image, true); 
  xhr.responseType = "blob";
  xhr.onload = function (e) {
          var reader = new FileReader();
          reader.onload = function(event) {
             var res = event.target.result;
             console.log(res);
             setFormatedImages([...formatedImages, res])
          }
          var file = this.response;
          reader.readAsDataURL(file)
  };
  xhr.send()
      })
    } else {
      setTimeout(() => {
        setShowNotif(true)
      }, 200);
    }
  },[spacePunkCollection, transformImage])


  const onChangeMetavaders = (img) => {
        setSelectedMetavaders(img)
  }

  const imageRef = createRef(null);
  const [image, takeScreenShot] = useScreenshot({
    type: "image/jpeg",
    quality: 1.0
  });

  const download = (image, { name = "metavaders-image", extension = "png" } = {}) => {
    const a = document.createElement("a");
    a.href = image;
    a.download = createFileName(extension, name);
    a.click();
  };


  const downloadScreenshot = () =>{
      takeScreenShot(ref.current).then(download);
      setLoadingDownload(false)
  } 


  const onSelectBgImage = (img)=> {
      if(selectedBgImage){
        setStep(2)
      }
    }

    const handleCheckSpacePunk = () => {
      if(spacePunkCollection && spacePunkCollection.length > 0 && walletAddress && nftCollection && nftCollection.length > 0){
        setTransformImage(true)
        setTimeout(() => {  
            setStep(1) 
        }, 200);
    
      } else if(walletAddress && spacePunkCollection && spacePunkCollection.length === 0 && nftCollection && nftCollection.length > 0 ){
        Toast.fail("You do not own any Space Punk", 4000, () => {
        });
      } else if(walletAddress && nftCollection && nftCollection.length === 0  ) {
        Toast.fail("You do not own any metavaders", 4000, () => {
        });
      } else {
        Toast.fail("You must connect your wallet to continue", 4000, () => {
        });
      }
    }


  return (
    <FadeIn delay={150}>
    <div className="container-wrap s-gallery">
   
      <div className="divider"></div>

      <div className="topmargin" ></div>
      <div className="s-gallery__topSection">
      <ReactReveal left>
      <div>
      <h2 className="new-section-text">{step === 0 ? `Collab Uniforms` : `Collab Uniforms`

      } </h2>

      <p className="s-gallery__topSection__header">
      To use this feature you will need to own a Metavaders NFT <a href="https://opensea.io/collection/metavaders" target="_blank">(which you can get here)</a> as well as a NFT of the corresponding project. For more details of how to use visit our discord page.
      </p>

      <ul className="activate-ul">

        <li>Connect your Metamask wallet.</li>
        <li>Select a corresponding project.</li>
        <li>Select the NFT you wish to dress up.</li>
        <li>Select the uniform you wish to update your NFT with.</li>
        <li>If you're happy with the look click "Download" to get this new image. </li>
        
      </ul>
      </div>
      </ReactReveal>
      
      </div>
      <div className="s-gallery__container">

      {step === 0 &&   <div className="s-gallery__card">
    <p className="crew-members-info">Select your corresponding project</p>

    <div className="s-gallery__images--background" style={{textAlign: "center", display:"flex", justifyContent: "center"}}>
<img onClick={() => handleCheckSpacePunk()} alt="Space Chunk" src={spaceChunkLogo}/>
</div>

    </div>}

     {step === 1 && formatedImages && formatedImages.length > 0 &&
    <div className="s-gallery__card">
    <p className="crew-members-info">Select the SpacePunk you would like to customize</p>

    <div className="s-gallery__images--background">
{formatedImages && formatedImages.map((img, index) => (<img key={index} className={`${selectedBgImage === img && `s-gallery__selected`}`} onClick={() => setSelectedBgImage(img)} key={index} src={img}/>))}
</div>

    </div> 
    }

    {
        step === 2 && <div className="s-gallery__card">
        <p className="crew-members-info">Add custom suit to your selected SpacePunk</p>
        <ReactReveal top>
        <div className="s-collab__armors ">
    {bgImages && bgImages.length > 0 && bgImages.map((item, index) => (<img key={item} className={`${selectedMetavaders.includes(item) && `s-gallery__selected`}`} onClick={() => onChangeMetavaders(item)} src={item}/>))}
    </div>
    </ReactReveal>

    <div ref={ref} className="s-gallery__images--combined">
        <img src={selectedBgImage} className="s-gallery__images--top"/>
        <img src={selectedMetavaders} className="s-gallery__images--top absolute"/>
   
    </div>
        </div>
    }

{step !== 0 &&
        <div className="s-gallery__btn">
          <button className="s-gallery__btn--back" onClick={() => step === 3 ? setStep(2) : step === 2 ? setStep(1) : step === 1 ? setStep(0): null}>
                Back
          </button>
        
            {step === 1 &&  <button  style={nftCollection && nftCollection.length === 0 ? {opacity: "0.8"} : null}  className="s-gallery__btn--next" onClick={() => onSelectBgImage()  }>
Next
</button> }
{step !== 1 &&   <button className="s-gallery__btn--download" onClick={downloadScreenshot}>
{loadingDownload ? <FaSpinner className="icon-spin"/> : `Download`}
                
            </button>}  
       </div>
        }

      </div>
      <Footer showFooter="displayDesktop"/>
    </div>
    </FadeIn>
  );
}

export default Collab;
