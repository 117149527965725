import React, { useEffect, useState, loadDataOnlyOnce } from "react";
import NavBar from "../../components/Navbar";
import animetasTarget from "../../assets/animetas.png";
import Toggle from 'react-toggle'
import Footer from "../../components/Footer";
import "reactjs-popup/dist/index.css";
import openSea from "../../assets/opensea.png";
import FadeIn from "react-fade-in";
import "./index.css";
import Modal from "../../components/Modal";
import { 
  connectWallet, 
  getCurrentWalletConnected, 
  collectionInWallet,
  getTokenURI,
  getInvaderBalance,   
} from "../../web3/interact";
import { FaSpinner, FaQuestionCircle } from "react-icons/fa";
import ReactTooltip from "react-tooltip";
import Toast from 'light-toast';

function Starship({nftCollection, toggle,
onUpdateWallet,
walletAddress,
setStatusState,
loading, setIsLoading,
  setToggle}) {
  const [selectedMetavader, setselectedMetavader] = useState();
  const [invasionTarget, setInvasionTarget] = useState();
  const [invasionBalance, setInvasionBalance] = useState();

  useEffect(async () => {
    const { address, status } = await getCurrentWalletConnected();
   
    onUpdateWallet(address);
    setStatusState(status);

    if (address != "") {
      setIsLoading(false)
    }

  }, []);
  


  const connectWalletPressed = async () => {
    setIsLoading(true)
    const walletResponse = await connectWallet();
    setStatusState(walletResponse.status);
    onUpdateWallet(walletResponse.address);
    setIsLoading(false)
    window.location.reload()
  };


const onInvasionSelect = async (value) => {
  setInvasionTarget(value)
 const result = await getInvaderBalance()

  setInvasionBalance(result)
 
}

const handleDisabledButton = () => {

  if(invasionTarget && invasionBalance && invasionBalance === "0"){
    Toast.fail("You do not own any animetas", 4000, () => {
     
    });
  }
}
  return (
    <section>
    <div className="container-wrap s-home" >
   
      <div className="divider" ></div>

     
      <div className="s-home__toggle">
 
</div>
     
      <FadeIn delay={300}>
    
      <h2 className="new-section-text">STARSHIP UPGRADE </h2>
      <p className="s-home__label">To upgrade please do the following:</p>
      <ol className="activate-ul">
        <li>Connect your wallet (if it is not yet connected).</li>
   
        <li>Select the starship you want to upgrade: Human Advanced, Mutant Advanced or Human Mothership</li>
        <li>Click "Upgrade" in which a pop up should appear.</li>
        <li>Check the look of the selected starships for upgrade. If you are fine with it click "Continue"</li>
        <li>Once the transaction is complete you should be able to refresh this page and see your upgraded NFT!</li>
      </ol>

     
      {nftCollection && nftCollection.length > 0 && walletAddress && !loading ? 
      <FadeIn delay={300}>
      <div className="crew-members-cont drop-shadow">
    
        <p className="crew-members-info">Select Starship</p>
        <div className="crew-members">
          
          {nftCollection.map((a, id) => (
            <div
              key={id}
              className={
                selectedMetavader == id
                  ? "crew-member selected"
                  : "crew-member"
              }
            >
              <img
                src={a.image}
                onClick={() => setselectedMetavader(id)}
              /> 
            </div>
          ))}
        </div>
        </div>
        </FadeIn>
: nftCollection && nftCollection.length === 0 && walletAddress && !loading ? 
<FadeIn delay={100}>
<div className="crew-members-cont drop-shadow">
    
<div className="crew-members s-home__opensea">
    <a
  href="https://opensea.io/collection/metavaders" target="_blank"
     className=""
    >
      <img
      style={{width: "100%", borderRadius: "10px"}}
        src={openSea}
        alt="member-img"
      /> 
    </a>

    <a  href="https://opensea.io/collection/metavaders" target="_blank" className="s-home__btn--more" id="OpenSea">
      <span>See More</span>
    </a> 

</div>
</div> 
</FadeIn>
: <div className="crew-members-cont drop-shadow">
    <p className="crew-members-info">Connect your wallet to select Starship</p>
    <div className="crew-members s-home__opensea">
        <button onClick={connectWalletPressed} href="https://opensebutton.io/collection/metavaders" target="_blank" className="s-home__btn--more" id="OpenSea">
          <span>{loading ? <FaSpinner className="icon-spin" /> : `Connect`}</span>
        </button> 
    </div>
    </div>
        }
      
    
     
      <div className="activate-btn-container">
       {
         invasionTarget && invasionBalance && invasionBalance === "0" ?    <button className={`activate-page-btn s-home__btn--second ${invasionTarget && invasionBalance && invasionBalance === "0" && `disabled`}`} id="walletButton" onClick={() => handleDisabledButton()} >
         <span>UPGRADE</span>
       </button> :  <Modal
          trigger={
            <button className={`activate-page-btn s-home__btn--second `} id="walletButton" >
              <span>UPGRADE</span>
            </button>
          }
          action="activate"
          selectedMetavader = {selectedMetavader}
          invasionTarget = {invasionTarget}
          nftMetadata = {nftCollection && nftCollection[selectedMetavader]}
        />
       }
       
      </div>
      <Footer />
      </FadeIn>
    </div>
    <ReactTooltip />
    </section>
  );
}

export default Starship;
