import React, { useEffect, useState, loadDataOnlyOnce } from "react";
import Toggle from 'react-toggle'
import Footer from "../../components/Footer";
import "reactjs-popup/dist/index.css";
import FadeIn from "react-fade-in";
import openSea from "../../assets/opensea.png";
import "./index.css";
import Modal from "../../components/Modal";
import { 
  connectWallet, 
  getCurrentWalletConnected, 
} from "../../web3/interact";
import { FaSpinner } from "react-icons/fa";
import ReactTooltip from "react-tooltip";

function Deactivate({nftCollection, toggle,
onUpdateWallet,
walletAddress,
setStatusState, 
loading, setIsLoading,
  setToggle}) {

  const [selectedMetavader, setselectedMetavader] = useState();
  const [invasionTarget, setInvasionTarget] = useState();


  useEffect(async () => {
    const { address, status } = await getCurrentWalletConnected();
   
    onUpdateWallet(address);
    setStatusState(status);

    if (address != "") {
      setIsLoading(false)
    }

  }, []);

  
  const connectWalletPressed = async () => {
    setIsLoading(true)
    const walletResponse = await connectWallet();
    setStatusState(walletResponse.status);
    onUpdateWallet(walletResponse.address);
    setIsLoading(false)
    window.location.reload()
  };


  return (
<section>
    <div className="container-wrap">
   
      <div className="divider"></div>

      
      <div className="s-home__toggle">
  <Toggle
    defaultChecked={toggle}
    icons={false}
    onChange={() => setToggle(!toggle)} />
    <div className="topmargin">
<p>Click here to toggle between Activate/De-Activate page</p>
      </div>
</div>
     
      <FadeIn delay={300}>
    
      <h2 className="new-section-text">DE-ACTIVATE</h2>
      <p className="s-home__label">To de-activate please do the following:</p>
      <ol className="activate-ul">
        <li>Connect your wallet (if it is not yet connected).</li>
        <li>Select the Metavader you wish to de-activate.</li>
        <li>Click "de-activate" in which a pop up should appear.</li>
        <li>Check the look of the de-activated Metavader NFT. If you are fine with it click "De-activate"</li>
        <li>Your Metamask wallet should ask you to confirm a small gas transaction. Confirm that.</li>
        <li>Wait for your transaction to complete. (You can then check the transaction on Etherscan).</li>
        <li>Once the transaction is complete you should be able to refresh this page and see your de-activated Metavader!</li>
        <li>Note: You will also need to refresh the metadata on Opensea to make sure it shows up there too.</li>
      </ol>

     

           
      {nftCollection && nftCollection.length > 0 && walletAddress && !loading ? 
      <FadeIn delay={300}>
      <div className="crew-members-cont drop-shadow">
    
        <p className="crew-members-info">Select Metavader</p>
        <div className="crew-members">
          
          {nftCollection.map((a, id) => (
            <div
              key={id}
              className={
                selectedMetavader == id
                  ? "crew-member selected"
                  : "crew-member"
              }
            >
              <img 
                src={a.image}
                onClick={() => setselectedMetavader(id)}
                alt="member-img"
              /> 
            </div>
          ))}
        </div>
        </div>
        </FadeIn>
: nftCollection && nftCollection.length === 0 && walletAddress && !loading ? 
<FadeIn delay={300}>
<div className="crew-members-cont drop-shadow">
    
<div className="crew-members s-home__opensea">
    <a
  href="https://opensea.io/collection/metavaders" target="_blank"
     className=""
    >
      <img
      style={{width: "100%", borderRadius: "10px"}}
        src={openSea}
        // onClick={() => setselectedMetavader(id)}
        alt="member-img"
      /> 
    </a>

    <a  href="https://opensea.io/collection/metavaders" target="_blank" className="s-home__btn--more" id="OpenSea">
      <span>See More</span>
    </a> 

</div>
</div> 
</FadeIn>
: <div className="crew-members-cont drop-shadow">
    <p className="crew-members-info">Connect your wallet to select Metavaders</p>
    <div className="crew-members s-home__opensea">
        <button onClick={connectWalletPressed} href="https://opensebutton.io/collection/metavaders" target="_blank" className="s-home__btn--more" id="OpenSea">
          <span>{loading ? <FaSpinner className="icon-spin" /> : `Connect`}</span>
        </button> 
    </div>
    </div>
        }
   

      <div className="activate-btn-container">
        {/* <button className="activate-page-btn" id="walletButton" onClick={activate}>
          <span>ACTIVATE</span>
        </button> */}
        <Modal
          trigger={
            <button className="activate-page-btn s-home__btn--second" id="walletButton">
              <span>DE-ACTIVATE</span>
            </button>
          }
          action="deactivate"
          selectedMetavader = {selectedMetavader}
          invasionTarget = {invasionTarget}
          nftMetadata = {nftCollection && nftCollection[selectedMetavader]}
        />
      </div>
      <Footer />
      </FadeIn>
    </div>
    <ReactTooltip />
    </section>
  );
}

export default Deactivate;
